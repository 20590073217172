var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-responsive"},[(_vm.rows)?_c('vue-good-table',{ref:"data-table",attrs:{"columns":this.columns,"rows":this.rows,"total-rows":this.rows.length,"select-options":{
        enabled: _vm.showSelect,
        selectOnCheckboxOnly: true
      },"search-options":{
        enabled: true
      },"compactMode":"","pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 25,
        position: 'top',
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All'
      },"sort-options":{
        enabled: true
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(
            props.column.field === 'action' && _vm.activeTab === 'Manufacturer'
          )?_c('span',[_c('span',{staticClass:"text-success mr-2"},[_c('i',{staticClass:"nav-icon i-Pen-2 font-weight-bold",on:{"click":function($event){return _vm.$emit('display-edit-form', props.row)}}})])]):(
            props.column.field === 'action' && _vm.$route.name === 'support'
          )?_c('span',[_c('router-link',{staticClass:"text-danger mr-2",attrs:{"to":{
              name: 'support-details',
              params: { id: props.row.id }
            }}},[_c('button',{staticClass:"btn btn-sm btn-success"},[_vm._v("View")])])],1):(
            props.column.field === 'is_open' && _vm.$route.name === 'support'
          )?_c('span',[(props.row.is_open)?_c('span',{staticClass:"badge badge-warning"},[_vm._v(" Open ")]):_c('span',{staticClass:"badge badge-success"},[_vm._v("Closed")])]):_c('span',[_vm._v(_vm._s(props.formattedRow[props.column.field]))]),(props.column.field === 'qr_code')?_c('span',{staticClass:"text-info mr-2"},[_c('i',{staticClass:"nav-icon fa fa-qrcode font-weigth-bold text-success",on:{"click":function($event){return _vm.$emit('display-qr-code', props.row)}}})]):_vm._e()]}}],null,false,3337951966)},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[(_vm.activeTab !== 'Delivered')?_c('button',{staticClass:"btn btn-success btn-icon m-1",attrs:{"type":"button"},on:{"click":_vm.moveToTransit}},[_c('span',{staticClass:"ul-btn__icon"},[_c('i',{staticClass:"i-Gear-2"})]),(_vm.activeTab === 'Manufacturer')?_c('span',{staticClass:"ul-btn__text"},[_vm._v("Move To Transit")]):(_vm.activeTab === 'In+Transit')?_c('span',{staticClass:"ul-btn__text"},[_vm._v("Move To Delivered")]):_vm._e()]):_vm._e()]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No records found. ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }