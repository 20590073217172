<template>
  <div>
    <div class="table-responsive">
      <vue-good-table
        ref="data-table"
        :columns="this.columns"
        :rows="this.rows"
        :total-rows="this.rows.length"
        :select-options="{
          enabled: showSelect,
          selectOnCheckboxOnly: true
        }"
        :search-options="{
          enabled: true
        }"
        compactMode
        v-if="rows"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 25,
          position: 'top',
          dropdownAllowAll: false,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All'
        }"
        :sort-options="{
          enabled: true
        }"
      >
        <div slot="selected-row-actions">
          <button
            v-if="activeTab !== 'Delivered'"
            type="button"
            class="btn btn-success btn-icon m-1"
            @click="moveToTransit"
          >
            <span class="ul-btn__icon"><i class="i-Gear-2"></i></span>
            <span v-if="activeTab === 'Manufacturer'" class="ul-btn__text"
              >Move To Transit</span
            >
            <span v-else-if="activeTab === 'In+Transit'" class="ul-btn__text"
              >Move To Delivered</span
            >
          </button>
        </div>
        <template slot="table-row" slot-scope="props" class="">
          <span
            v-if="
              props.column.field === 'action' && activeTab === 'Manufacturer'
            "
          >
            <span class="text-success mr-2">
              <i
                class="nav-icon i-Pen-2 font-weight-bold"
                @click="$emit('display-edit-form', props.row)"
              ></i>
            </span>
          </span>
          <span
            v-else-if="
              props.column.field === 'action' && $route.name === 'support'
            "
          >
            <router-link
              :to="{
                name: 'support-details',
                params: { id: props.row.id }
              }"
              class="text-danger mr-2"
            >
              <button class="btn btn-sm btn-success">View</button>
            </router-link>
          </span>
          <span
            v-else-if="
              props.column.field === 'is_open' && $route.name === 'support'
            "
          >
            <span v-if="props.row.is_open" class="badge badge-warning">
              Open
            </span>
            <span v-else class="badge badge-success">Closed</span>
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          <span v-if="props.column.field === 'qr_code'" class="text-info mr-2">
            <i
              class="nav-icon fa fa-qrcode font-weigth-bold text-success"
              @click="$emit('display-qr-code', props.row)"
            ></i>
          </span>
        </template>
        <div slot="emptystate">
          No records found.
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DataTable",
  props: {
    columns: Array,
    rows: Array,
    activeTab: String
  },
  computed: {
    showSelect() {
      if (this.activeTab === "Manufacturer") {
        return true;
      }
      return false;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    moveToTransit() {
      const selectedRows = this.$refs["data-table"].selectedRows;
      if (this.activeTab == "Manufacturer") {
        if (this.currentRouteName === "routers") {
          this.updateRouters(selectedRows, "In Transit");
        } else {
          this.updateKiosks(selectedRows, "In Transit");
        }
      } else if (this.activeTab == "In+Transit") {
        if (this.currentRouteName === "routers") {
          this.updateRouters(selectedRows, "Delivered");
        } else {
          this.updateKiosks(selectedRows, "Delivered");
        }
      }
    },
    async updateRouters(routers, location) {
      const updateRouterLocationUrl = "v1/routers/update-location-status/";
      await axios
        .post(updateRouterLocationUrl, { routers, location })
        .then(response => {
          if (response.status === 200) {
            if (response.data.detail) {
              this.$swal("Success", response.data.detail, "success");
            } else {
              this.$swal("Success", "Routers Updated successfully", "success");
            }
            this.$emit("get-routers", this.activeTab, true);
          }
        })
        .catch(error => {
          if (error.response.data.detail) {
            this.$swal("Error", `${error.response.data.detail}`, "warning");
          } else {
            this.$swal("Error", "Could not update routers", "warning");
          }
        });
    },
    async updateKiosks(kiosks, location) {
      const updateKioskLocationUrl = "v1/kiosks/update-location-status/";
      await axios
        .post(updateKioskLocationUrl, { kiosks, location })
        .then(response => {
          if (response.status === 200) {
            if (response.data.detail) {
              this.$swal("Success", response.data.detail, "success");
            } else {
              this.$swal("Success", "Kiosks Updated successfully", "success");
            }
            this.$emit("get-kiosks", this.activeTab, true);
          }
        })
        .catch(error => {
          if (error.response.data.detail) {
            this.$swal("Error", `${error.response.data.detail}`, "warning");
          } else {
            this.$swal("Error", "Could not update kiosks", "warning");
          }
        });
    }
  }
};
</script>
